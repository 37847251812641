import React from 'react'
import starIcon from '../../assets/images/star-icon.png'
import howItWork from '../../assets/images/how-its-work.png'

const WhyChooseUs = () => {
    return (
        <section className="how-its-work-area ptb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="how-its-work-content">
                            <span className="sub-title">
                                <img src={starIcon} alt="banner" /> 
                                People Love Us
                            </span>
                            <h2>Why Choose Us?</h2>
                            <p>We believe success lies in its commitment to running events with professionalism and expertise, and in identifying strongly with their outcome. This sense of responsibility means we always strive to add value and are pro-active in solving problems and in adopting client procedures.</p>
                            <div className="inner-box">
                                <div className="single-item">
                                    <div className="count-box">
                                        1
                                    </div>
                                    <h3>Audit Current QA Process</h3>
                                    <p>We belive there must be number of ways we can accellerate QA/Test automation process.</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        2
                                    </div>
                                    <h3>Design Custom Solutions</h3>
                                    <p>We belive ther is no one solution can fix all customer challenges, every every company will be required their own specific solutions for day to day challenges.</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        3
                                    </div>
                                    <h3>Cost effective</h3>
                                    <p>Our offshore/onsite resourcing model will save to our customer's budget.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="how-its-work-image">
                            <img src={howItWork} alt="banner" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WhyChooseUs